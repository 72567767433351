<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          PHARMACONNECT
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-overlay :show="showLoading" rounded="sm">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              BienVenue! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Veuillez saisir votre nom d'utilisateur et votre mot de passe
            </b-card-text>

            <!-- form -->
            <b-form class="auth-login-form mt-2" @submit.prevent="">
              <!-- email -->
              <b-form-group label="Nom d'utilisateur" label-for="login-email">
                <b-form-input id="login-email" v-model="userEmail" name="login-email"
                  placeholder="pharmaConnect-CLT-000001" />
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Mot de passe</label>
                  <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <b-input-group class="input-group-merge">
                  <b-form-input id="login-password" v-model="password" class="form-control-merge"
                    :type="passwordFieldType" name="login-password" placeholder="············" />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>


              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block @click="login()">
                <feather-icon icon="UnlockIcon" class="mr-50" />
                <span class="align-middle">Connexion</span>
              </b-button>
            </b-form>

            <b-card-text class="text-center mt-2">
              <span>Nouveau sur notre plateforme ? </span>
              <b-link :to="{ name: 'page-auth-register-v2' }">
                <span>&nbsp;Devenir notre client</span>
              </b-link>
            </b-card-text>

            <!-- divider -->
            <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->
          </b-col>
        </b-overlay>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
// import {
//   BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
// } from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  isUserLoggedIn
} from "@/auth/utils";
import {
  initialAbility
} from "@/libs/acl/config";

export default {
  components: {
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      showLoading: false,
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.showLoading = true;
      useJwt.login({
        email: this.userEmail,
        password: this.password,
      })
        .then(response => {
          this.showLoading = false;
          if (response.data.code == '200') {
            if (!localStorage.getItem("lastItemUpdate")) {
              localStorage.setItem("lastItemUpdate", "01-01-2000 - 00:00:00");
            }
            if (!localStorage.getItem('lastBlockedSync')) {
              const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD format
              localStorage.setItem('lastBlockedSync', today);
            }
            const userData = response.data;
            useJwt.setToken(userData);
            useJwt.setUserData(userData);
            // useJwt.setCurrentCompany(userData.company);
            this.$ability.update(userData.ability);
            if (['ROLE_CUSTOMER', 'ROLE_EDITOR', 'ROLE_SUPPLIER'].includes(userData.role) && userData.firstTime) {
              this.$router.push({ name: 'password' });
            } else
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role));
          } else {
            this.$toast.clear();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Authentification invalide',
                icon: 'XIcon',
                text: 'nom d\'utilisateur ou mot de passe incorrect. Veuillez vérifier vos informations.',
                variant: 'danger',
              },
            }, {
              timeout: 10000
            })
          }
        }).catch((error) => {
          this.showLoading = false;
          console.log(error);
        })
    },
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData');
      localStorage.removeItem('sessionId');
      localStorage.removeItem('company');
      this.$ability.update(initialAbility)
      this.$forceUpdate()
    }
  },
  mounted() {
    if (!isUserLoggedIn()) {
      this.logout();
    } else {
      this.$router.replace(getHomeRouteForLoggedInUser(JSON.parse(localStorage.getItem('userData')).role));
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
